<template>
  <div>
    <b-row>
      <b-col lg="12">
        <b-card-code title="Liste des Communes">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-add-commune
            variant="outline-primary"
          >
            Ajouter
          </b-button>
          <!-- input search -->
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Recherche</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Recherche"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>

          <!-- table -->
          <vue-good-table
            :columns="communecolumns"
            :rows="communes"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Action -->
              <span v-if="props.column.field === 'action'">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item @click="editCommune(props.row)">
                      <feather-icon
                        icon="Edit2Icon"
                        class="mr-50"
                      />
                      <span>Modifier</span>
                    </b-dropdown-item>
                    <!-- <b-dropdown-item>
                      <feather-icon
                        icon="TrashIcon"
                        class="mr-50"
                      />
                      <span>Delete</span>
                    </b-dropdown-item> -->
                  </b-dropdown>
                </span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10','20','50']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap "> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
          <!--    Modal Add-->
          <b-modal
            id="modal-add-commune"
            cancel-variant="outline-secondary"
            ok-title="Enregistrer"
            cancel-title="Close"
            centered
            size="lg"
            title="Ajouter une Commune"
            @ok="addCommune"
          >
            <validation-observer ref="simpleRules">
              <b-form>
                <b-row>
                  <b-col
                    md="6"
                    lg="6"
                  >
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Nom"
                        rules="required"
                      >
                        <b-form-input
                          v-model="commune.name"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Nom"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="6"
                    lg="6"
                  >
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Departement"
                        vid="departement"
                        rules="required"
                      >
                        <b-form-select
                          v-model="commune.departement"
                          :options="departements"
                          :state="errors.length > 0 ? false:null"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                </b-row>
              </b-form>
            </validation-observer>
          </b-modal>
          <!--    Modal Edit-->
          <b-modal
            id="modal-edit-commune"
            cancel-variant="outline-secondary"
            ok-title="Enregistrer"
            cancel-title="Close"
            centered
            size="lg"
            title="Modifier une Commune"
            @ok="updateCommune"
          >
            <validation-observer ref="simpleRules">
              <b-form>
                <b-row>
                  <b-col
                    md="6"
                    lg="6"
                  >
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Nom"
                        rules="required"
                      >
                        <b-form-input
                          v-model="commune.name"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Nom"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                 <b-col
                    md="6"
                    lg="6"
                  >
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Departement"
                        vid="departement"
                        rules="required"
                      >
                        <b-form-select
                          v-model="commune.departement"
                          :options="departements"
                          :state="errors.length > 0 ? false:null"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-modal>
        </b-card-code>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton, BForm, BModal, BFormSelect, BRow, BCol, BPagination, BFormGroup, BFormInput, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import communeMixin from '../@core/mixins/communeMixin'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BRow,
    BCol,
    BModal,
    BForm,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  mixins: [communeMixin],
  data() {
    return {
      required,
      pageLength: 10,
      dir: false,
      departements: [
        { value: null, text: 'Veuillez sélectionner un departement' },
      ],
      communecolumns: [
        {
          label: 'Nom',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par Nom',
          },
        },
        {
          label: 'Departement',
          field: 'departement.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par department',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      commune: {
        name: null,
        departement: null,
      },
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.$store.dispatch('mainAppStore/fetchDepartementsListItems').then(response => {
      console.log(response.data)
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < response.data.length; index++) {
        const departement = {
          value: response.data[index].id,
          text: response.data[index].name,
        }
        this.departements.push(departement)
      }
    })
      .catch(err => {
        console.error(err.response.headers)
      })
  },
  methods: {
    addCommune(e) {
      e.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('mainAppStore/addCommuneItem', this.commune).then(response => {
            console.log(response.data)
            this.$bvModal.hide('modal-add-commune')
            this.$swal({
              title: 'Succes',
              html: 'Sous Commune ajoutée avec succes',
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            })
          })
            .catch(err => {
              this.$swal({
                title: 'Erreur',
                html: err.response.data.message,
                icon: 'error',
                timer: 3000,
                showConfirmButton: false,
              })
              console.error(err.response)
            })
        }
      })
    },
    editCommune(commune) {
      this.commune.id = commune.id
      this.commune.name = commune.name
      this.commune.departement = commune.departement
      this.$bvModal.show('modal-edit-commune')
    },
    updateCommune(e) {
      e.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('mainAppStore/updateCommuneItem', this.commune).then(response => {
            console.log(response.data)
            this.$bvModal.hide('modal-edit-commune')
            this.$swal({
              title: 'Succes',
              html: `${response.data.name} est modifier avec succes`,
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            })
          })
            .catch(err => {
              this.$swal({
                title: 'Erreur',
                html: err.response.data.message,
                icon: 'error',
                timer: 3000,
                showConfirmButton: false,
              })
              console.error(err.response.headers)
            })
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
